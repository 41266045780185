import React, { useEffect, useState } from 'react'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function DummyPage() {
    const [id, setId] = useState('a43e4c');
    const navigate = useNavigate();

    const createRequest = async () => {
        const obj = {"event_code": "epson-blitz-2024", "name": "Tang Han Yang", "contact_no" : "0142233994", "email" : "hanyang147@gmail.com", "sku" : "V11H977052", "purchased_date" : "2024-10-01", "purchased_value" : "0", "serial_number": "EPSON123", "invoice_no_img": "https://www.piclumen.com/wp-content/uploads/2024/10/piclumen-colorize-result-2.webp"};
        const objString = JSON.stringify(obj);
        const objEncoded64 = btoa(objString);
        const objEncodedUrl = encodeURIComponent(objEncoded64);
        
        try {
            const request = await axios.get(`https://api.epsonrewards.my/v1/api-encryption?token=${objEncodedUrl}`, 
                {
                headers: {
                  Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMjMsImV4cCI6NDg4NzIyODgyNn0.vWUOB7q8p28_b1Yl0sRTPYRoefEZ5iOXhgjDHAhESlI`
                }
            });
            const data = request.data.data.encrypted_details;
            const dataEncodedUrl = encodeURIComponent(data);
            navigate(`/?data=${dataEncodedUrl}`);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        createRequest();
    }, []);

    return (
        <div>
            <h1>- Dummy Page -</h1>
        </div>
    )

}

